<template>
  <div v-if="src" style="height: 100%; overflow: hidden" v-loading="loading">
    <iframe
      :src="src"
      allowfullscreen="true"
      scrolling="auto"
      frameborder="0"
      style="width: 100%; height: 100%"
    ></iframe>
  </div>
</template>

<script>
import { XJS_URL } from "@/config";
export default {
  data() {
    return {
      loading: false,
      src: null,
    };
  },
  beforeRouteLeave(to, from, next) {
    this.src = null;
    next();
  },
  created() {
    this.comp_type = this.$store.state.loginUser.comp.comp_type;
    this.src = XJS_URL;
    if (this.$route.query.path && this.$route.query.path.length > 0) {
      this.src += this.$route.query.path;
    }
    // else{
    //     this.src+="/center";
    // }
    if (this.src.indexOf("?") >= 0) {
      this.src +=
        "&token=" +
        this.$store.state.loginUser.token +
        "&tp=" +
        this.comp_type +
        "&siteid=0";
    } else {
      this.src +=
        "?token=" +
        this.$store.state.loginUser.token +
        "&tp=" +
        this.comp_type +
        "&siteid=0";
    }
  },
};
</script>

<style>
</style>